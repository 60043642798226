/* .Button {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    color: white;
    padding: 5px 5px;
    font-size: 1.5em;
    border: 0.2px solid white;
    border-radius: 1px;
    min-width: 10vw;
}

.Button:hover {
    cursor: pointer;
    background-color:black;
} */

.Button{
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 150px;
    border:1px solid gray;
    color:gray;
    font-size: 1.2em;
    padding:10px;
    border-radius:4px;
    cursor: pointer;
    -webkit-transition:all .25s linear;
    transition:all .5s linear;
  }
  .Button:hover{
    color:black;
    background-color:rgba(255, 255, 255, 1);
  }