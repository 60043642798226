
.AvatarContent {
    /* min-height: 100vh; */
    background-color: black;
}

.ReturnHome {
    min-height: 8vh;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: flex-end;
    color: white;
}

.AvatarMain {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 92vh;
    /* height: 100%; */
    color: white;
}

.AvatarInput {
    height: 30px;
    width: 195px;
}

.AvatarButton {
    height: 50px;
    width: 200px;
    cursor: pointer;
}

.AvatarImage {
    width: 200px;
    height: 200px;
}