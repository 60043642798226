

.ProfileContent {
    background-color: black;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    height: 100vh;
    flex-flow: wrap;
}

.ProfileImage {
    width: 300px;
    height: 500px;
}

.TraitList {
    display:grid;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 1.2em;
    min-height: 50vh;
    color: white;
}

.TraitItem {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    min-width: 20vw;
}