

.Main {
    box-sizing: border-box;
    /* padding-bottom: 20px; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    background-color: black;
    color: white;
    font-family: "Times New Roman", Times, serif;
    text-align: center;
    padding-bottom: 30px;
}

.Content {
    /* background-color:lightblue; */
    min-height: 90vh;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;

}

h1 {
    font-weight: normal;
    font-size: 2.5em;
}

p {
    font-size: 1.3em;
    max-width: 950px;
    line-height: 1.4em;
}

.ConnectedAccount {
    display: flex;
    flex-direction: row;
    margin-bottom: 10px;
}

.Address {
    margin-left: 10px;
}

.RemainCount {
    margin-top: 10px;
    margin-bottom: 60px;
}

.Copyright {
    font-size: 1em;
    color: rgba(255, 255, 255, 0.5);
}