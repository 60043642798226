
.BoxList {
    max-width: 665px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
}

.Box {
    margin: 10px;
    width: 200px;
    height: 150px;
    /* border: 1px solid white; */
    /* display: flex; */
    /* flex-direction: column; */
    justify-content: flex-start;
    align-items: flex-start;
    /* justify-content: flex-start; */
    background: rgba(255, 255, 255, .4);
    color: black;
    border-radius: 2px;
}

.Selected {
    background-color: black;
    color: white;
    border: 1px solid white;
}

.BoxTitle {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 20px;
    font-size: 1.2em;
}

.Desc {
    text-align: left;
    margin-left: 20px;
    margin-right: 20px;
    word-wrap: break-word;
}

