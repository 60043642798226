
.Header {
    min-height: 10vh;
    min-width: 60vw;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    font-family: "Times New Roman", Times, serif;
}

.SocialMedia {
    display: flex;
    flex-direction: row;
}

.MediaItem {
    margin-right: 15px;
    font-size: 1.1em;
}

.Avatar {
    background: linear-gradient(
        to right,
        rgba(0,0,0,0) 0%,
        rgba(134, 35, 200,0) 10%,
        rgba(134, 35, 200, 0.3) 20%,
        rgba(134, 35, 200,0.4) 30%,
        rgba(134, 35, 200,0.5) 40%,
        rgba(134, 35, 200,0.8) 50%,
        rgba(134, 35, 200,0.5) 60%,
        rgba(134, 35, 200,0.4) 70%,
        rgba(134, 35, 200,0.3) 80%,
        rgba(134, 35, 200,0) 90%,
        rgba(0,0,0,0) 100%)
}


a {
    color: #fff;
    text-decoration:none;
    /* margin-right: 20px; */
}